import React from 'react';
import styled from 'styled-components';
import { graphql } from 'gatsby';
import { colors, styles } from 'midtype-backend';

import Layout from '../layout/Layout';

const Styled = styled.div`
  h1,
  h2,
  h3,
  h4,
  h5 {
    color: ${colors.BLUE_DARK()};
    font-weight: 600;
  }
  h1 {
    font-size: 2.75rem;
    margin-bottom: 0.5rem;
  }
  h2 {
    font-size: 1.75rem;
    margin-bottom: 0rem;
    margin-top: 2rem;
    color: ${colors.GRAY_4()};
  }
  h3 {
    font-size: 1.25rem;
    margin-bottom: 0.5rem;
  }
  h4 {
    font-size: 1.125rem;
  }
  h5 {
    font-size: 1rem;
  }
  p,
  li {
    font-size: 0.9rem;
    line-height: 1.5rem;
    margin-bottom: 1rem;
  }
  code,
  pre {
    font-size: 13px;
  }
  pre {
    border-radius: 5px;
    background: rgba(0, 0, 0, 0.03);
  }
  pre code {
    background: none !important;
  }
  strong {
    font-weight: 500;
    color: ${colors.BLACK()};
  }
  li {
    color: ${colors.GRAY_4()};
    margin-bottom: 0.5rem;
  }
  blockquote {
    margin: 0;
    margin-bottom: 2rem;
    border-bottom: 1px solid ${colors.GRAY_2()};
  }
  blockquote p {
    font-size: 1.5rem;
    line-height: 2.25rem;
  }
  figure {
    margin: 2rem 0;
  }
  figure a span {
    margin: 0 0 0.75rem 0 !important;
    box-shadow: ${styles.BOX_SHADOW};
    border-radius: 6px;
    overflow: hidden;
    transition: 250ms all;
    &:hover {
      box-shadow: ${styles.BOX_SHADOW_DARK};
    }
  }
  figcaption {
    font-size: 0.8rem;
    line-height: 1rem;
    font-weight: 500;
    color: ${colors.GRAY_3()};
  }
  ul,
  ol {
    display: block;
    padding-left: 2rem;
    margin-bottom: 1rem;
  }
  ul li {
    list-style: disc;
  }
  ol li,
  ul li {
    margin-left: 0;
    padding-left: 0.5rem;
  }
  .content {
    max-width: 50rem;
    margin: 8rem auto;
  }
`;

const LegalTemplate: React.FC<any> = props => {
  return (
    <Layout pageTitle={props.data.markdownRemark.frontmatter.title}>
      <Styled>
        <div
          className="content"
          dangerouslySetInnerHTML={{ __html: props.data.markdownRemark.html }}
        />
      </Styled>
    </Layout>
  );
};

export const query = graphql`
  query($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      fields {
        slug
      }
      frontmatter {
        title
      }
    }
  }
`;

export default LegalTemplate;
